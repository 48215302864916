<template>
  <el-dialog
    :visible.sync="editBox"
    width="70%"
  >
    <div class="box">
      <div class="box-header">
        <h4>{{ pageTitle }}</h4>
      </div>
      <shop-form
        v-if="product"
        :submit-text="pageTitle"
        :product="product"
        @on-submit="editProduct"
      >
      </shop-form>
      <placeholder-form v-else></placeholder-form>
    </div>
  </el-dialog>
</template>

<script>
import ShopForm from './components/_EditForm'
import ShopTempService from '@admin/services/ShopTempService'
import flatry from '@admin/utils/flatry'
import PlaceholderForm from '@core/components/Placeholder/PlaceholderForm'

export default {
  name: 'editTemp',
  components: { PlaceholderForm, ShopForm },
  data () {
    return {
      pageTitle: '编辑素材',
      product: null,
      editBox: false
    }
  },
  // async created () {
  //   const { data } = await flatry(ShopTempService.edit(this.$router.currentRoute.query.id))

  //   if (data) {
  //     this.product = data.data
  //   }
  // },
  methods: {
    async createdGo (item) {
      this.product = null
      this.editBox = true
      const { data } = await flatry(ShopTempService.edit(item.id))

      if (data) {
        this.product = data.data
      }
    },
    async editProduct (product, success, callback) {
      const { data } = await flatry(
        ShopTempService.edit(product.id, product)
      )

      if (data) {
        this.$message.success(data.msg)
        // this.$router.replace({ path: '/shop-temp' })
        this.editBox = false

        this.$emit('editOk')
        success()
      }

      callback()
    }
  }
}
</script>

<style scoped>
</style>
